import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = _ => {
  return (
    <Layout activePage="about">
      <SEO title="About" />
      <div className="container mb-16">
        <article className="prose">
          <h2>About Me</h2>
          <p>
            Hi there! I'm Matt. I'm a software engineer currently based in 
            England, UK. Having been passionate about technology from a young age,
            I've been writing code for {new Date().getFullYear() - 2015}{" "}
            years&nbsp;
            <span role="img" aria-label="Man Technologist">
              👨‍💻
            </span>
          </p>
          <p>
            I followed the tech path throughout the education system where I
            achieved a first class bachelor's honours degree specialising in
            software engineering&nbsp;
            <span role="img" aria-label="Man Student">
              👨‍🎓
            </span>
            &nbsp;I worked as a software engineer full-time
            whilst completing my degree, which allowed me to reap the benefits
            of real-world experience early on.
          </p>
          <p>
            As such, I've worked with a variety of different technologies. From
            writing mods for games in Java at the age of 14 to working on safety
            critical embedded software and desktop/tablet data analysis tools in
            my late teens. Today, I work on enterprise e-commerce websites
            and serverless applications harnessing Azure.
          </p>
          <p>
            I'm always eager to learn more, so I've created this website to act
            as the one small consistant place on the internet where I can
            showcase all the side project's I work on.
          </p>
          <p>
            I hope you find some of it interesting&nbsp;
            <span role="img" aria-label="Grinning Face">
              😀
            </span>
            &nbsp;feel free to reach out to me through email or other social
            media streams if you want to chat! Links can be found at the bottom
            of the page.
          </p>
        </article>
      </div>
    </Layout>
  )
}

export default About
